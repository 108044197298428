import React from "react";
import CodeSkills from "../CodeSkills";
// import SoftSkills from "./SoftSkills";

const Skills = () => {
  return (
    <div>
      <CodeSkills />
      {/* <SoftSkills /> */}
    </div>
  );
};

export default Skills;
