import React from "react";

const Transcriptions = () => {
  return (
    <div>
      <h2>Transcriptions</h2>
      <div>List of transcriptions</div>
    </div>
  );
};

export default Transcriptions;
