import React from "react";
// import ColorChanger from "../staticComponents/ColorChanger";
import Greeting from "../staticComponents/Greeting";

const Index = () => {
  return (
    <div>
      {/* <div>Index</div> */}
      <Greeting />
    </div>
  );
};

export default Index;
