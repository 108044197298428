import React from "react";
// import GoogleCalendar from "../../../staticComponents/GoogleCalendar"

const Calendar = () => {
  return (
    <div>
      <h1>Calendar</h1>
			{/* <GoogleCalendar/> */}
    </div>
  );
}

export default Calendar;
