import React from "react";
import ContactForm from "../ContactForm";

const Contact = () => {
  return (
    <div>
      <h2>Contact</h2>
      <ContactForm />
    </div>
  );
};

export default Contact;
